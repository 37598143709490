<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Empirical Formula - Report Question 2</h2>

      <p class="mb-3">
        Write the balanced equation for each of the reactions described below. Please use whole
        number coefficients for all reactions!
      </p>

      <p class="mb-2">
        a) Solid magnesium reacting with atmospheric oxygen to produce solid magnesium oxide.
      </p>

      <chemical-notation-input
        v-model="inputs.balancedEquation1"
        dense
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Solid magnesium reacting with atmospheric nitrogen to produce solid magnesium nitride.
      </p>

      <chemical-notation-input
        v-model="inputs.balancedEquation2"
        dense
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) Water reacting with the by-product magnesium nitride (solid) to produce ammonia gas and
        solid magnesium hydroxide.
      </p>

      <chemical-notation-input
        v-model="inputs.balancedEquation3"
        dense
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        d) Solid magnesium hydroxide decomposing to produce water vapor and magnesium oxide.
      </p>

      <chemical-notation-input
        v-model="inputs.balancedEquation4"
        dense
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';

export default {
  name: 'OleMissLab7ReportQuestion2',
  components: {
    ChemicalNotationInput,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        balancedEquation1: null,
        balancedEquation2: null,
        balancedEquation3: null,
        balancedEquation4: null,
      },
    };
  },
};
</script>
